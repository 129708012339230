<template>
    <Modal :isOpen="true" :showBackButton="false" @onClose="close" model-popup-class="tw-max-w-2xl">
      <preview :container-id="orderId" :mode="'my-orders'" />

      <template v-slot:footer v-if="items.price">
        <div class="tw-mt-2 tw-flex tw-justify-between tw-items-center">
          <button v-if="items.can_edit" class="btn btn_transparent tw-my-0 tw-space-x-2 tw-flex tw-items-center tw-px-3 tw-rounded-sm" @click="edit">
            <svg-icon name="solid/edit" class="tw-h-4"></svg-icon>
            <span class="tw-self-end">EDIT</span>
          </button>
          <button v-else class="btn btn_transparent tw-my-0 tw-space-x-2 tw-flex tw-items-center tw-px-3 tw-rounded-sm" @click="edit">
            <svg-icon name="solid/eye" class="tw-h-4"></svg-icon>
            <span class="tw-self-end">VIEW</span>
          </button>
<!--          <span v-if="canSeePrice" class="tw-text-white">{{ formatPrice(items.price.order_total) }}</span>-->
        </div>
      </template>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/Generic'
import {mapGetters, mapState} from "vuex";
import { ContentLoader } from 'vue-content-loader'
import {changePriceForm} from '@/helpers/aroundPrice'
import OrderSummary from '@/components/orders/orderSummaryTable/OrderSummary'
import * as abilityNames from '@/components/../helpers/abilityNames'

import preview from '@/components/item-wizard/components/orders/preview'

export default {
    name: 'OrderItemInfoPopup',
    components: {
        Modal,preview
    },
    props: ['orderId', 'can_edit'],
    created() {
        // this.$store.commit('orderPreview/id', this.orderId);
        // return this.$store.dispatch('orderPreview/getPreviewLessInfo', {silent: true, loadLocationsAutomatically: true} );
    },
    data() {
      return {
        abilityNames,
      }
    },
    computed: {
        ...mapGetters({
            items: 'orderPreview/items',
        }),
        ...mapState('orderPreview', ['locationLoadingStatus']),
        locations() {
           return this.items.locations
        },
        ...mapGetters('auth', ['can']),
        canSeePrice() {
          return this.can(abilityNames.SEE_PRICE)
        }
    },
    methods: {
        edit() {
          this.$router.push(`/order-preview/${this.items.order_id}`);
        },
        // isLocationLoading(location) {
        //   return _.get(this,`locationLoadingStatus[${location}][isLoading]`, true)
        // },
        close() {
            this.$emit('onClose')
        },
        // formatPrice(val) {
        //   if(!val.includes(',')) val = Number(val).toFixed(2)
        //   return `AUD $ ${changePriceForm(val)}`
        // }
    }
}
</script>

<style lang="scss">

</style>
