<template>
  <div class="col-12" v-if="showPrice">
    <Receipt :summary="items.price"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  // import {changePriceForm} from '../../../helpers/aroundPrice'
  import * as abilityNames from '@/components/../helpers/abilityNames'
  import {defineAsyncComponent} from "vue";
  const Receipt = defineAsyncComponent(() => import('@/components/item-wizard/components/estimate/item/summary/Receipt'));

  export default {
    name: "OrderSummary",
    components: {Receipt},

    data(){
      return {
        price: '',
        abilityNames,
      }
    },
    mounted() {
      this.$store.dispatch('auth/updateAbilities')
    },
    props: {
      showSettings: {
        type: Boolean,
        default: true,
      }
    },
    watch: {
      items(){
        return this.price = this.items.price
      }
    },

    methods: {
      openSettings() {
        return this.$store.commit('orderPreview/setSettingsWindow', true);
      },

      openTotalPrice() {
        return this.$store.commit('orderPreview/setTotalPriceWindow', true);
      },

      refactorPrice(price){
        return changePriceForm(price)
      }
    },
    computed: {
      ...mapGetters('orderPreview', ['items']),
      ...mapGetters('auth', ['can']),
      showPrice() {
        return this.can(abilityNames.SEE_PRICE)
      }
    },
  }
</script>
