<template>
  <transition name="fade">
    <div
      v-show="isOpen"
      class="modal modal_wrap generic-modal"
    >
      <div class="modal_popup" :class="modelPopupClass">
        <div class="modal-content-wrapper">
          <slot name="header">
            <div class="d-flex justify-content-between pb-3 pr-2">
              <div>
                <portal-target name="modal-back-arrow" v-show="!hideBackButtonAtAccessories ">
                  <span
                    v-show="showBackButton"
                    class="action-btn"
                    @click="back"
                  >
                    <svg-icon name="chevron-left"
                      class="lg"
                    />
                  </span>
                </portal-target>
              </div>
              <div>
                <span
                  class="action-btn"
                  @click="close"
                >
                  <svg-icon name="times"
                    class="lg"
                  />
                </span>
              </div>
            </div>
          </slot>
          <div class="container modal_content">
            <slot />
          </div>
        </div>
        <slot name="footer" />
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    name: 'GenericModal',
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: true,
        },
        modelPopupClass: {
            type: String,
            default: 'modal_full',
        }
    },
    methods: {
        close() {
            this.$emit('onClose')
        },
        back() {
            this.$emit('onBack')
        }
    },
  computed: {
      ...mapGetters({
        hideBackButtonAtAccessories : 'orderPosition/getHideBackButtonAtAccessoriesState'
      })
  },
}
</script>

<style lang="scss">
.generic-modal {
  .modal_content {
    max-height: calc(100vh - 220px);
  }
}
</style>

<style scoped lang="scss">
@import '../../../src/assets/scss/utils/vars';
.modal-content-wrapper {
  position: relative;
}
.action-btn{
  display: flex;
  color: #ffffff;
  cursor: pointer;
  transition: all .3s;
  :hover {
    color: $green;
  }

  .lg{
    font-size: 2em;
  }
}
</style>
