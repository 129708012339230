<template>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12" v-for="(item, indexItem) in itemsOrder" :key="indexItem">

            <div class="orderItem tw-relative" :style="{border: '1px solid black'}">
                <h2 class="orderItem__title orderItem__colour" :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}" :style="{backgroundColor: item.status_detail.theme_color}">
          <span class="tw-inline-block tw-flex tw-items-center tw-space-x-2">
            <svg-icon :name="item.status_detail.state_icon" />
            <span>
              {{item.state_info.name}} #{{item.number}}
            </span>
          </span>

          <span>{{item.data}}</span>

          <span @click="showInfoForOrderId = item.container_id"
                class="orderItem__iconRight"
          >
              <span class="tw-inline-block tw-h-5">
                <svg-icon name="info-circle"/>
              </span>
          </span>
                </h2>

                <div class="orderItem__boxInfo tw-grid tw-grid-cols-1 tw-space-x-1 tw-font-helvetica">
                  <div class="tw-space-y-0">
                    <p class="text_default tw-flex tw-items-center reference_text">
                      <span v-if="item.order_reference">Reference : <strong>{{item.order_reference}}</strong></span>
                      <span v-else>Reference : No Reference</span>
                    </p>
                  </div>
                </div>
                <div class="orderItem__boxInfo tw-grid tw-grid-cols-2 tw-space-x-2 tw-font-helvetica">
                  <div class="tw-space-y-2">
                    <p class="text_default" :title="item.owner">
                        <svg-icon name="user" v-if="item.owner_type === 'customer'"
                                           :style="{color: item.status_detail.theme_color}"/>
                        <svg-icon name="handshake" v-if="item.owner_type === 'dealer'"
                                           :style="{color: item.status_detail.theme_color}"/>
                        {{item.owner}}
                    </p>
                    <p class="text_default tw-flex tw-items-center">
                        <svg-icon :name="item.status_detail.status_icon" :style="{color: item.status_detail.theme_color}"/>
                        <span>
                          {{item.status_detail.status_description}}
                        </span>
                    </p>
                  </div>
                  <div class="tw-space-y-2">
                      <div class="tw-flex tw-items-center">
                        <svg-icon name="solid/window-frame" :style="{color: item.status_detail.theme_color}"></svg-icon>
                        <span>{{ item.item_count }}</span>
                      </div>
                      <div class="tw-flex tw-items-center">
                        <svg-icon name="solid/sack-dollar" :style="{color: item.status_detail.theme_color}"/>
                        ${{priceCalc(item.amount)}}
                      </div>
                  </div>
                </div>

                <div class="orderItem__footer tw-flex tw-py-0 tw-font-helvetica"
                     :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}"
                     :style="{backgroundColor: item.status_detail.theme_color}"
                >

                    <div class="tw-flex-1 tw-text-center tw-flex tw-items-center tw-justify-center tw-py-2">
                      <button @click="actionOpened = item.id === actionOpened? 0: item.id" class="btn tw-font-helvetica tw-text-white tw-text-xl tw-bg-transparent tw-border-0 tw-p-0 tw-flex tw-items-center tw-space-x-2"
                              :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}">
                        <svg-icon v-if="actionOpened === item.id"  name="solid/square-arrow-down"></svg-icon>
                        <svg-icon v-else name="solid/square-arrow-right"></svg-icon>
                        <span>Actions</span>
                      </button>
                    </div>

                  <div v-if="!item.prod_review" @click="toProduct(item)" class="tw-w-1/2 tw-text-center tw-flex tw-items-center tw-justify-center tw-py-2 tw-border-l tw-border-white tw-border-solid">
                    <button v-if="item.can_edit" class="btn tw-font-helvetica tw-text-white tw-text-xl tw-bg-transparent tw-border-0 tw-p-0 tw-flex tw-items-center tw-space-x-2"
                            :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}">
                      <svg-icon name="solid/edit"></svg-icon>
                      <span>Edit</span>
                    </button>
                    <button v-else class="btn tw-font-helvetica tw-text-white tw-text-xl tw-bg-transparent tw-border-0 tw-p-0 tw-flex tw-items-center tw-space-x-2">
                      <svg-icon name="solid/eye"></svg-icon>
                      <span>View</span>
                    </button>
                  </div>
                </div>
                <div v-if="actionOpened === item.id" class="tw-absolute" style="left: -1px;width: 100.6%;">
                  <div @click="actionOpened = 0" class="tw-fixed tw-inset-0" style="z-index: 5"></div>
                  <div class="tw-absolute tw-font-helvetica tw-top-0.5 tw-rounded tw-shadow-md tw-left-0 tw-w-full tw-bg-red-500 status-bg-color text-white" style="z-index: 6" :style="{backgroundColor: item.status_detail.theme_color}"
                       :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}">
                    <div v-for="action in item.actions" @click="() => makeAction(action, item)" class="tw-flex tw-space-x-2 tw-text-white hover:tw-text-gray-100 tw-items-center tw-px-2 tw-py-2 tw-border-t tw-border-white tw-border-solid tw-cursor-pointer"
                         :class="{orderItem__darkFont: (item.state_info.code == 'EST' || item.state_info.code == 'QOT') && item.status_info.code == 'PEND'}">
                      <svg-icon :name="getStatusIcon(action.icon, action.name)" />
                      <span>{{ action.name }}</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div v-if="pager.total > itemsOrder.length" class="col-12 load_more" @click="loadMore">
            Load more...
        </div>

        <transition name="fade">
            <delete-modal-global v-if="deleteGlobalOpen"></delete-modal-global>
        </transition>

        <transition name="fade">
            <delete-order :from="'list'" v-if="deleteOpen" :deleteAction="deleteAction"></delete-order>
        </transition>

        <transition name="fade">
            <estimate-quote v-if="estimateQuote"></estimate-quote>
        </transition>

      <transition name="fade" >
        <OrderItemInfoPopup v-if="showInfoForOrderId !== 0" @onClose="showInfoForOrderId = 0" :order-id="showInfoForOrderId"/>
      </transition>

    </div>
</template>

<script>
    import router from "../../router";
    import {mapGetters} from 'vuex';

    import {changePriceForm} from '../../helpers/aroundPrice';
    import * as abilityNames from '@/components/../helpers/abilityNames'
    import {defineAsyncComponent} from "vue";

    import OrderItemInfoPopup from './OrderItemInfoPopup'
    import GenericQuestion from "@/components/modals/GenericQuestion";
    import store from "../../store/store";
    const DeleteOrder = defineAsyncComponent(() => import('@/components/modals/DeleteOrder'));
    const EstimateQuote = defineAsyncComponent(() => import('@/components/modals/EstimateQuote'));
    const DeleteModalGlobal = defineAsyncComponent(() => import('@/components/modals/DeleteModalGlobal'));

    export default {
        name: "orderItem",
        components: {
          DeleteModalGlobal,
          DeleteOrder,
          EstimateQuote,
          OrderItemInfoPopup,
          GenericQuestion,
        },
        data() {
            return {
                isAccessor: false,
                len: 0,
                ask_submit: false,
                submit_id: 0,
                abilityNames,
                actionOpened: 0,
                showInfoForOrderId: 0,
            }
        },

        methods: {
            async deleteAction() {
              const orderId = this.$store.getters['orderPreview/id']
              await this.$store.dispatch('orderPreview/deleteOrderSilent', orderId)
              this.$store.commit('orderPreview/delete', false);
              this.$emit('onRefresh')
            },
            makeAction(action, item) {
              const actions = {
                'Clone': this.cloneActions,
                'Send quote': this.sendQuoteAction,
                'Send estimate': this.sendEstimateActions,
                'Submit order': this.sendToProduction,
                'Specification': this.viewSpecsAction,
                'Delete': this.deleteOrder,
              }
              const notFound = () => {
                console.error('No action found')
              }
              (actions[action.name] || notFound)(item)

            },
            sendToProduction(item) {
              this.isSubmit(item.id)
            },
            sendEstimateActions(item) {
                const estimateActions = this.estimateActions
                store.commit('genericQuestion/openModal', {
                    question: `Are you sure you want to email this ESTIMATE to your client ${item.owner}`,
                    buttons: [
                      {
                        name: 'Back',
                        icon: 'solid/step-backward',
                        handler: function () {
                          return false
                        }
                      },
                      {
                        name: 'Send Estimate',
                        icon: 'solid/clipboard-list',
                        handler: function (skip) {
                          estimateActions(item.id)
                          store.commit('optionColours/openModal', false)
                        }
                      },
                    ]
                  }
                )
                // this.estimateActions(item.id)
            },
            sendQuoteAction(item) {
                const quoteActions = this.quoteActions
                store.commit('genericQuestion/openModal', {
                    question: `Are you sure you want to email this QUOTE to your client ${item.owner}`,
                    buttons: [
                      {
                        name: 'Back',
                        icon: 'solid/step-backward',
                        handler: function () {
                          return false
                        }
                      },
                      {
                        name: 'Send Quote',
                        icon: 'solid/tape',
                        handler: function (skip) {
                          quoteActions(item.id)
                          store.commit('optionColours/openModal', false)
                        }
                      },
                    ]
                  }
                )
                // this.quoteActions(item.id)
            },
            viewSpecsAction(item) {
              this.viewSpecsActions(item.id)
            },
            viewSpecsActions(id) {
              this.$store.dispatch('orderPreview/productionReview', id);
            },
            cloneActions(item) {
              this.$store.dispatch('orderPreview/cloneOrder', item.id);
            },
            getStatusIcon(name, description) {
              const icons = {
                'Clone': 'solid/clone',
                'Send quote': 'solid/tape',
                'Send estimate': 'solid/clipboard-list',
                'Submit order': 'solid/industry-windows',
                'Specification': 'solid/memo',
                'Delete': 'solid/trash-alt',
              }
              return icons[description] || name
            },
            closeSubmit() {
                this.ask_submit = false;
                this.submit_id = 0;
            },

            loadMore(){
                // this.$store.commit('orderItems/updatePage', {
                //     current_page: this.pager.page + 1
                // })
                const selected = this.$store.state['filterOrders'].selectedStatuses
                let statuses = selected.filter(x => x.id !== 0).map(x => x.status)
                let states = selected.filter(x => x.id !== 0).map(x => x.state)
                if(states.length === 0) {
                  let selectedType = this.$store.getters['filterOrders/selectedFilterType']
                  const types = {
                    'all': ['DRFT','EST','QOT','ORD'],
                    'orders': ['ORD'],
                    'quotesEstimatesAndDrafts': ['DRFT','EST','QOT']
                  }
                  states = types[selectedType]
                  statuses = undefined
                }
                this.$store.dispatch('orderItems/loadMore', {states, statuses});
            },

            prepareEdit(item, order, room) {
                this.$store.dispatch('orderItem/prepareUpdate', {item: item, order: order, room: room});
            },
            deleteItem(itemId, orderId) {
                this.$store.commit('deleteModalGlobal/setStatus', true);
                this.$store.commit('deleteModalGlobal/setParams', {
                    itemType: 'oi',
                    action: 'orderItem/delete',
                    actionAfter: {
                        action: 'orderPreview/getPreview',
                        data: null
                    },
                    actionData: {
                        id: orderId,
                        itemId: itemId
                    }
                });
            },
            openAccessories(e) {
                let target = e.target;
                target.closest('.toggleList').classList.toggle('toggleList_open');
            },

            openAllRoom(e) {
                let target = e.target;
                target.closest('.orderItem').classList.toggle('orderItem_open');
            },

            toProduct(elem) {
                router.push(`/order-preview/${elem.id}`);
                // localStorage.setItem('order_id', elem.id);
            },


            completeOrder(elem) {
                this.$store.dispatch('orderPreview/completeOrder', elem);
            },

            deleteOrder(elem) {
                this.$store.commit('orderPreview/id', elem.id);
                this.$store.commit('orderPreview/delete', true);
            },

            roomsLength(item) {
                if (Object.keys(item).length > 1) {
                    return true;
                }
                return false;
            },

            locationItemsList(id) {
                if (!this.itemsOrder[id].rooms)
                    return;
                let _this = this;
                return _.filter(this.itemsOrder[id].rooms, function (location, key) {
                    location.name = key;
                    if (key !== "Accessories") {
                        return _this.itemsOrder[id].rooms;
                    }
                });
            },

            accessoriesItemsList(id) {
                let _this = this;
                return _.filter(this.itemsOrder[id].rooms, function (location, key) {
                    location.name = key;
                    if (key === "Accessories") {
                        return _this.itemsOrder[id].rooms[key].name;
                    }
                });
            },

            async quoteActions(id) {
                await this.$store.dispatch('orderDetails/quoteOrder', id);
                this.$emit('onRefresh')
            },

            async estimateActions(id) {
              await this.$store.dispatch('orderDetails/estimateOrder', id);
              this.$emit('onRefresh')
            },

            isSubmit(id) {


              var all = _.flatMap(_.filter(this.itemsOrder, o => {
                return o.id === id
              }) , (l, i) => {
                return _.flatMap(l.rooms, i => {return i})
              })

              console.log(all)
              if (_.filter(all, i => { return i && i.style_id && i.style_id === 46 }).length > 0){
                let $store = this.$store

                this.$store.commit('genericQuestion/openModal', {question: 'This order contains BasiX products that have been discontinued or specification has changed thus cannot be submitted to production. Use Bulk Edit function to change these items to another product or remove them.',
                  skip: false,
                  buttons: [
                    {
                      name: 'Back',
                      icon: 'solid/step-backward',
                      handler: function () {
                        $store.commit('genericQuestion/closeModal')
                      }
                    }]
                })

              }else {
                const submitActions = this.submitActions
                store.commit('genericQuestion/openModal', {
                    question: `Are you sure you want to submit this order to production?`,
                    buttons: [
                      {
                        name: 'Back',
                        icon: 'solid/step-backward',
                        handler: function () {
                          return false
                        }
                      },
                      {
                        name: 'Submit',
                        icon: 'solid/industry-windows',
                        handler: function (skip) {
                          submitActions(id)
                        }
                      },
                    ]
                  }
                )              }
            },

            async submitActions(id) {
                await this.$store.dispatch('orderDetails/submitOrder', id)
                this.ask_submit = false
                this.$emit('onRefresh')
            },

            icon(i) {
                let result = i.split(' ')[1].substr(3);
                return result;
            },

            disabledBtn(item) {
                if (Object.keys(this.itemsOrder[item].rooms).length) {
                    return false;
                }
                else return true;
            },

            priceCalc(price) {
                return changePriceForm(price);
            },

        },

        computed: {
            ...mapGetters('auth', ['can']),
            itemsOrder() {
                return this.$store.getters['orderItems/items'];
            },
            deleteGlobalOpen() {
                return this.$store.getters['deleteModalGlobal/get']('status');
            },
            ...mapGetters({
                estimateQuote: 'orderPreview/estimateQuote',
                deleteOpen: 'orderPreview/delete',
                pager: 'orderItems/pager'
            }),
            canSubmitOrders(){
                return this.can(abilityNames.SUBMIT_ORDERS)
            },
            canSendQuotes(){
                return this.can(abilityNames.SEND_QUOTES)
            },
            canSendEstimates(){
                return this.can(abilityNames.SEND_ESTIMATES)
            }


        },

        created() {
            // this.$store.dispatch('orderItems/getOrders');
            this.$store.dispatch('auth/updateAbilities')
        },

        beforeDestroy() {
            this.$store.commit('filterOrders/selectedStatuses', []);
            this.$store.commit('orderItems/filteredItems', []);
            this.$store.commit('orderItems/clearItems');
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../src/assets/scss/utils/vars';

    .button__title {
        position: relative;
    }

    .orderItem__listItem {
        max-height: 250px;
        overflow: auto;
    }

    .orderItem_open {
        .orderItem__listItem {
            max-height: none;
        }
    }

    .toggleList__title {
        padding-right: 0;
    }

    .orderItem__listTitle {
        padding-top: 0;
    }

    .orderItem__footer {
        text-align: right;
        font-family: $bold;
    }
    .btn{
        margin: 0 10px;

        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }
    }

    .load_more{
        text-align: center;
        border: 2px #55b494 solid;
        font-size: 1.2em;
        margin-bottom: 25px;
        padding: 10px 0;
        cursor: pointer;
    }
    .reference_text{
      margin-bottom: -10px;
    }

</style>
